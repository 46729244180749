import "../../../styles/news.css";
import React from "react";
import api from "../../../services/api";
import { imagemURL, countNewsHome } from "../../../services/variables";
import Loading from "../others/Loading";
import SubTitle from "../others/title/SubTitle";
import { useQuery } from "react-query";

export default function News() {
  const { data, isLoading, isError } = useQuery(
    "news-home",
    async () => {
      const response = await api.get(`/searchLast/${countNewsHome}`);
      return response.data.res;
    },
    {
      staleTime: 5 * 60 * 1000, // 5 minutos
      cacheTime: 10 * 60 * 1000, // 10 minutos
    }
  );

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <p>Error loading news...</p>;
  }

  return (
    <div>
      <SubTitle subTitle={"Últimas notícias"} border={true} />
      <div className="box_news">
        {data?.length > 0 ? (
          data.map((news) => (
            <a
              href={`/noticia/${news.ID}`}
              target="_self"
              rel="noopener noreferrer"
              className="news_item"
              key={news.ID}
            >
              <img
                width={320}
                height={250}
                alt={news.TITULO}
                src={imagemURL + news.IMG}
                className="news_img"
                loading="lazy"
              />
              <div className="news_content">
                <a
                  href={`/noticia/${news.ID}`}
                  target="_self"
                  rel="noopener noreferrer"
                  className="news_title"
                >
                  {news.TITULO}
                </a>
                <p className="news_date">
                  Publicado em: {new Date(news.DATA_POST).toLocaleDateString()}
                </p>
                <div className="cat_views">
                  <p className="news_category">{news.CATEGORIA}</p>
                  <p className="news_views">
                    {news.VISUALIZACAO}
                    <img
                      width={30}
                      height={30}
                      alt="views"
                      src="/icons/views.svg"
                      className="icon-views"
                    />
                  </p>
                </div>
              </div>
            </a>
          ))
        ) : (
          <p>Nenhuma notícia encontrada.</p>
        )}
      </div>
    </div>
  );
}
