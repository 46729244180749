// Alto Parnaíba
const idPortalApi = "24ab449ecc6a5305cc973c81039fd9c15c204fec";
const idForTransparenciaAdm = "6d83a282-79b4-4a9b-8460-49b14a94910b";

module.exports = {
  idPortalApi: `${idPortalApi}`,
  tokenLink: `${idPortalApi}`, //Token para acessar o WWW.transparência
  idForTransparenciaAdm: `${idForTransparenciaAdm}`,
  // Produção
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  // urlApi: `http://localhost:8004/${idPortalApi}`,
  urlADM: `https://administracaopublica.com.br`,

  dnsPortal: "santahelena.ma.gov.br",

  NomePortal: "Santa Helena",
  ufPortal: "MA",
  NomeTipoPortal: "Prefeitura Municipal", //Prefeitura ou Câmara
  TipoPortal: 3, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true, //Essa opção informa se a rota de visualização de uma única noticia está disponível (Raposa gera as noticias pelo lado do servidor com nextjs)

  countNewsHome: 9, //quantidade de noticias na home

  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario

  link: "http://www.transparenciadministrativa.com.br", //Onde o token abaixo ser[a utilizado]
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem", //Onde o token abaixo ser[a utilizado]

  urlEmDeploy: "https://santahelena.ma.gov.br/", //usada para link de compartilhamento
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/", //link para a imagem
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  corIcones: "#000080",

  //links externos
  link_youtube: `#`,
  link_localize: `https://maps.app.goo.gl/mR41HG1BvLNbLvXQA`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15947.128431064633!2d-45.3019885!3d-2.2353972!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x92b31f7eb4edf5d1%3A0xf795243c8f28eda!2sSanta%20Helena%2C%20State%20of%20Maranh%C3%A3o%2C%2065208-000!5e0!3m2!1sen!2sbr!4v1731324779330!5m2!1sen!2sbr",
};

